<template>
<!-- 主营业务 -->
    <div class="business">
        <div v-if="!ifPhone" class="computer" style="padding-top: 30px">
            <!-- <div class="banner">
                <div class="content">
                <div class="boxs">
                    <el-carousel :height="bannerHeight" :interval="4000">
                        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                            <img class="cp" @click="Skip(item.href)" :src="item.url" :alt="item.name" style="width: 100%; height: 100%">
                        </el-carousel-item>
                    </el-carousel>
                    </div>
                </div>
            </div> -->
            <div class="box">
                <div class="">
                    <div class="">
                        <div class="dec">
                            <img src="@/assets/images/dec_b_left.png" alt="">
                            <span class="big_title">{{getLanguageText(languageType, '物流服务产品')}}</span>
                            <img src="@/assets/images/dec_b_right.png" alt="">
                        </div>
                    </div>
                    <!-- <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_fs">
                        <el-breadcrumb-item :to="{ path: '/business/false' }" >{{node.businessTranslate}}</el-breadcrumb-item>
                        <el-breadcrumb-item>{{title.name}}</el-breadcrumb-item>
                    </el-breadcrumb> -->
                    <div class="business_main">
                        <!-- <div class="business_title small_title">{{title.name}}</div> -->
                        <!-- <div class="business_text">{{bizSynopsis}}</div> -->
                        <!-- <div class="business_title">{{node.detailTranslate}}：</div> -->
                        <!-- <div id="bizDetail" v-loading="htmlLoading" class="business_text main_body" v-html="bizDetail"></div> -->
                        <div class="product">
                            <div :class="[item.ifShow ? 'product_item' : '', 'w31']" v-for="(item, index) in productData" :key="index">
                                <div class="p_item_img" v-show="item.ifShow">
                                    <img :src="item.img" alt="">
                                </div>
                                <div class="p_item_content" v-show="item.ifShow">
                                    <div class="p_item_head">{{item.head}}</div>
                                    <div class="p_item_text">{{item.text}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="phone">
            <div class="box">
                <div class="dec dflex_center">
                    <img src="@/assets/images/dec_b_left.png" alt="">
                    <span>{{getLanguageText(languageType, '物流服务产品')}}</span>
                    <img src="@/assets/images/dec_b_right.png" alt="">
                </div>
                <div class="business_main">
                    <div class="phone_product">
                        <div :class="[item.ifShow ? 'product_item' : '', 'w31']" v-for="(item, index) in productData" :key="index">
                            <div class="p_item_img" v-show="item.ifShow">
                                <img :src="item.img" alt="">
                            </div>
                            <div class="p_item_content" v-show="item.ifShow">
                                <div class="p_item_head">{{item.head}}</div>
                                <div class="p_item_text">{{item.text}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 联系我们 -->
            <div class="home_relation">
                <div class="content">
                    <div class="boxs">
                        <div class="p_dec">
                            <img src="@/assets/images/dec_b_left.png" alt="">
                            <span>{{node.contactTranslate}}</span>
                            <img src="@/assets/images/dec_b_right.png" alt="">
                        </div>
                        <div class="home_relation_main">
                            <div class="home_relation_mains" :class="{'home_relation_bor': item.id == 2}" v-for="item in relationList" :key="item.id">
                                <i :class="item.icon" class="icon"></i>
                                <div class="home_relation_text" style="margin-left:20px">
                                    <span class="relation_name">{{item.name}}</span>
                                    <span class="relation_text">{{item.text}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <phone-menu></phone-menu>
        </div>
    </div>
</template>

<script>
import phoneMenu from '../../components/phoneMenu.vue'
import { sitebusiness } from '@/api/index'
export default {
    data() {
        return {
            id: 1,
            title: '',
            bannerHeight: '600px',
            bannerList: [],
            bizSynopsis: '',
            bizDetail: '',
            ifPhone: false,
            htmlLoading: false,
            productData: [
                { img: require('@/assets/images/product/1.png'), head: '头程', text: '一站式服务对接，满足跨境电商品牌客户全球销售的规划要求，国内外统一窗口对接，实时响应客户精细化和定制化需求;统一的WMS和TMS系统服务,OMS客户端直接管理,并提供持续升级的数字化物流服务体验', ifShow: true },
                { img: require('@/assets/images/product/2.png'), head: '空运', text: '制单，提货，订舱，报关，协商，监管转运，临时仓储，分拨配送，代购货运保险，协调机场地面操作，实时空运货物信息跟踪及反馈服务', ifShow: true },
                { img: require('@/assets/images/product/3.png'), head: '海运', text: '普通货物运输FCL(整柜承运)/(LCL)拼箱承运，食品冷柜货物运输，危险品运输，延伸服务为客户包船服务(散装船-限欧美)，项目定制运输方案', ifShow: true },
                { img: require('@/assets/images/product/4.png'), head: '报关', text: '专业的报关团队提供包括但不限于文件准备与提交、商品分类、税费和关税计算以及合规性咨询在内的一系列增值服务，为客户提供一站式解决方案', ifShow: true },
                { img: require('@/assets/images/product/5.png'), head: '清关', text: '专业团队提供包括但不限于预先文件准备与提交、商品分类和编码、仓储和物流安排以及合规性咨询在内的一系列增值服务，为客户提供全面服务的清关代理', ifShow: true },
                { img: require('@/assets/images/product/6.png'), head: '海外仓储', text: '16万㎡自营仓资源,欧美日多条核心出口专线,行业领先的逆向物流服务体系,海外本地团队管理,严格的仓储和配送管理标准', ifShow: true },
                { img: require('@/assets/images/product/7.png'), head: '尾程物流', text: '物流服务能力集中覆盖北美、欧盟、韩日和澳大利亚等主要消费国家,并进一步向新兴市场扩展(东南亚&非洲)', ifShow: true },
                { img: require('@/assets/images/product/8.png'), head: '卡派服务', text: '30+运输车辆,配送服务分布全球12个国家和地区,为客户提供高效、经济、安全、便捷的服务体验，帮助客户实现业务目标，加强企业在市场中的竞争力', ifShow: true },
                { img: '', head: '', text: '', ifShow: false },
            ]
        }
    },
    components: {
        phoneMenu
    },
    computed: {
        businessCategory(){
            return this.$store.state.businessCategory
        },
        node(){
            return this.$store.state.node
        },
        languageType(){
            return this.$store.state.languageType
        },
        liftBusinessNum(){
            return this.$store.state.liftBusinessNum
        },
        relationList() {
            return this.$store.state.relationList
        },
    },
    created() {
        this.id = this.$route.params.id != 'false' ? this.$route.params.id : 1
    },
    mounted() {
        let result = window.matchMedia('(max-width: 1300px)');
        // this.bannerHeight = document.getElementsByClassName('banner')[0].offsetHeight + 'px'
        /* if (result.matches) {
            this.bannerHeight = document.getElementsByClassName('banner')[0].offsetHeight+ 'px'
        } else {
            
            } */
        let maxResult = window.matchMedia('(max-width: 980px)');
        if (maxResult.matches) {
            var h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            // let f = document.getElementsByClassName('phone_footer')[0].offsetHeight
            // let he = document.getElementsByClassName('p_header')[0].offsetHeight
            // document.getElementsByClassName('business')[0].style.minHeight = (h - f - he - 2) + 'px'
            // this.ifPhone = true;
            // this.bannerHeight = '200px'
        }
        // this.getList()
        this.productData = [
            { img: require('@/assets/images/product/1.png'), head: this.getLanguageText(this.languageType, '头程'), text: this.getLanguageText(this.languageType, '头程文本'), ifShow: true },
            { img: require('@/assets/images/product/2.png'), head: this.getLanguageText(this.languageType, '空运'), text: this.getLanguageText(this.languageType, '空运文本'), ifShow: true },
            { img: require('@/assets/images/product/3.png'), head: this.getLanguageText(this.languageType, '海运'), text: this.getLanguageText(this.languageType, '海运文本'), ifShow: true },
            { img: require('@/assets/images/product/4.png'), head: this.getLanguageText(this.languageType, '报关'), text: this.getLanguageText(this.languageType, '报关文本'), ifShow: true },
            { img: require('@/assets/images/product/5.png'), head: this.getLanguageText(this.languageType, '清关'), text: this.getLanguageText(this.languageType, '清关文本'), ifShow: true },
            { img: require('@/assets/images/product/6.png'), head: this.getLanguageText(this.languageType, '海外仓储'), text: this.getLanguageText(this.languageType, '海外仓储文本'), ifShow: true },
            { img: require('@/assets/images/product/7.png'), head: this.getLanguageText(this.languageType, '尾程物流'), text: this.getLanguageText(this.languageType, '尾程物流文本'), ifShow: true },
            { img: require('@/assets/images/product/8.png'), head: this.getLanguageText(this.languageType, '卡派服务'), text: this.getLanguageText(this.languageType, '卡派服务文本'), ifShow: true },
            { img: '', head: '', text: '', ifShow: false },
        ]
    },
    methods: {
        getImgIntoView(num = 0, type = false) {
            if(type) {
                let dom = document.getElementById('bizDetail')
                let imgList = dom.getElementsByTagName('img')
                imgList[num].scrollIntoView(false)
            } else {
                let _this = this
                let dom = document.getElementById('bizDetail')
                let imgList = dom.getElementsByTagName('img')
                if(_this.liftBusinessNum != 1) {
                    for (let index = 0; index < imgList.length; index++) {
                        const img = imgList[index];
                        if(index == _this.liftBusinessNum) {
                            img.onload = function() {
                                img.scrollIntoView(false)
                                _this.htmlLoading = false
                            }
                            break
                        }
                    }
                } else {
                    _this.htmlLoading = false
                }
            }
        },
        getList() {
            let siteCode = this.$store.state.siteCode
            let businessCategory = this.id || 1
            let data = `siteCode=${siteCode}&businessCategory=${businessCategory}`
            this.htmlLoading = true
            sitebusiness(data).then((res) => {
                if (res && res.code == 0) {
                    this.bannerList = []
                    let imgs = JSON.parse(res.data.imgs)
                    imgs.map(item => {
                        this.bannerList.push({
                            url: this.imgTransition(item.urlName),
                            name: item.urlName,
                            href: item.href
                        })
                    })
                    this.bizSynopsis = res.data.bizSynopsis
                    this.bizDetail = res.data.bizDetail
                    this.title = this.businessCategory[this.id - 1]
                    this.$nextTick(() => {
                        // this.getImgIntoView()
                    })
                }
            }).catch(err => {
                console.log('err', err);
                this.htmlLoading = false
            })
        },
        Skip(url) {
            if(url) {
                window.open(url)
            }
        },
        getConsult () {
            this.$store.commit('setId', 4)
            sessionStorage.setItem('currId', 4)
            this.$router.push({name: 'Consultation'})
        },
        // 图片路径转换
        imgTransition(str) {
            return this.$store.state.domainImg + str.slice(0, 1) + '/' + str.slice(1, 2) + '/' + str
        },
    },
    watch: {
        $route: {
            handler: function(val, oldVal){
                this.id = val.params.id
                this.title = this.businessCategory[this.id - 1]
            },
            // 深度观察监听
            deep: true
        },
        liftBusinessNum(newVal, oldVal){
            if(newVal || newVal == 0) {
                // this.getImgIntoView(newVal, true)
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    .dflex_center {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2vh 0;
        span {
            font-size: 2vh !important;
            height: auto !important;
        }
        img {
            width: 15vw;
            height: 3vw;
        }
    }
    .phone_product {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        .w31 {
            width: 49%;
        }
        .product_item {
            border: solid 1px #f2f2f2;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            align-items: center;
            transition: .3s;
            margin-bottom: 2.5vh;
            .p_item_img {
                width: 100%;
                display: flex;
                justify-content: center;
                height: 23vh;
                background: #D3E8FD;
                padding: 2vh 0;
                img {
                    width: 60%;
                    height: 100%;
                }
            }
            .p_item_content {
                padding: 1vh;
                box-sizing: border-box;
                min-height: 32vh;
                .p_item_head {
                    font-size: 2.6vh;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.38;
                    letter-spacing: normal;
                    color: #231f20;
                    margin-bottom: 1.5vh;
                    font-family: 'Open Sans', "Helvetica Neue Light", Helvetica, Arial, sans-serif;
                }
                .p_item_text {
                    font-size: 1.8vh;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.56;
                    letter-spacing: normal;
                    color: #444;
                    font-family: 'Open Sans', "Helvetica Neue Light", Helvetica, Arial, sans-serif;
                }
            }
        }
        .product_item:hover {
            box-shadow: 0 0.2vh 1.4vh 0 rgba(0, 0, 0, 0.3);
            border-color: transparent;
        }
    }
    .product {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        justify-content: space-evenly;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10px;
        .w31 {
            width: 31%;
        }
        .product_item {
            // width: 31%;
            border: solid 1px #f2f2f2;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            align-items: center;
            transition: .3s;
            margin-bottom: 25px;
            .p_item_img {
                width: 100%;
                display: flex;
                justify-content: center;
                height: 300px;
                background: #D3E8FD;
                padding: 20px 0;
                img {
                    width: 60%;
                    height: 100%;
                }
            }
            .p_item_content {
                padding: 20px;
                box-sizing: border-box;
                height: 230px;
                .p_item_head {
                    font-size: 26px;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.38;
                    letter-spacing: normal;
                    color: #231f20;
                    margin-bottom: 15px;
                }
                .p_item_text {
                    font-size: 18px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.56;
                    letter-spacing: normal;
                    color: #444;
                }
            }
        }
        .product_item:hover {
            box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.3);
            border-color: transparent;
        }
    }
::v-deep.el-breadcrumb {
    font-size: 26px;
}
::v-deep .el-carousel__indicators--horizontal {
    bottom: 40px;
}
</style>
<style lang="scss">
@media screen and (max-width: 1300px) {
    .business_text {
        text-indent: 0 !important;
        img {
            width: 80% !important;
        }
        p {
            img {
                width: 20% !important;
                height: auto;
            }
        }
    }
    /* .business {
        min-height: 144.2vw;
    } */
    .phone_main_body {
        img {
            width: 100% !important;
        }
    }
}
@media screen and (max-width: 1400px) {
    .business_text {
        text-indent: 0 !important;
        img {
            width: 65% !important;
        }
        p {
            img {
                width: 10% !important;
                height: auto;
            }
        }
    }
    /* .business {
        min-height: 144.2vw;
    } */
    .phone_main_body {
        img {
            width: 100% !important;
        }
    }
}
@media screen and (max-width: 980px) {
    .business_text {
        text-indent: 0 !important;
        p {
            img {
                width: 80% !important;
                height: auto;
            }
        }
    }
    .business {
        min-height: 144.2vw;
    }
    .phone_main_body {
        img {
            width: 100% !important;
        }
    }
}
</style>